import React, {useEffect} from 'react';
import { navigate } from 'gatsby';

const Index: React.FC<Record<string, unknown>> = () => {
  useEffect(() => {
    void navigate('/upload/');
  }, []);
  return null;  
}

export default Index;
